import classes from "./BoxSubTitle.module.css";
import React from "react";

const BoxSubTitle = ({ className, children, style }) => {
  return (
    <div
      className={[classes.boxEntitySubTitle, className].join(" ")}
      style={style}
    >
      <p>{children}</p>
    </div>
  );
};

export default BoxSubTitle;
