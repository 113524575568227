import classes from "./SubsytemSearch.module.css";

import { InputOnChange } from "../../UI/input/Input";
import { useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import search_icon from "./../../../assets/icons/other/search_icon_24x24.png";

import Button from "@material-ui/core/Button";
import { useStore } from "../../../hooks/store";
import { useEffect } from "react";
import SubsystemPopupMenuVars from "../popupMenu/vars/SubsystemPopupMenuVars";
import Badge from "@material-ui/core/Badge";
import SubsystemPopupMenu from "../popupMenu/SubsystemPopupMenu";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubsystemSearch = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [varsList, setVarsList] = useState([]);
  const optionRef = useRef("");
  const typeIdRef = useRef("");
  const sensorsLengthRef = useRef(null);

  const searchInputRef = useRef(null);
  const state = useStore()[0];

  console.log(state);

  const search = useLocation().search;
  const pathName = useLocation().pathname;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (searchInputRef) console.log(searchInputRef.current);
  }, [searchInputRef]);

  useEffect(() => {
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        typeIdRef.current = tempTypeIdActive[0].id;
        props.clearVars();
        const sensors = state.sensors.filter(
          (sensor) => sensor.typeId === currTypeURL
        );

        let tempVarList = [];
        if (tempTypeIdActive[0].id.toString() !== "9") {
          for (let i = 0; i < sensors.length; i++) {
            for (let y = 0; y < sensors[i].metrics.length; y++) {
              if (!tempVarList.includes(sensors[i].metrics[y].content))
                tempVarList.push(sensors[i].metrics[y].content);

              if (sensors[i].typeId === "8") break;
            }
          }
        } else {
          tempVarList = tempVarList.concat([
            "Άδειοι",
            "Κάτω από τη μέση",
            "Περίπου στη μέση",
            "Σχεδόν πλήρεις",
            "Πλήρεις",
          ]);
        }
        setVarsList(tempVarList);
      }
    }
  }, [search, state.types, state.sensors]);

  console.log(varsList);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickListItem2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const clickSubSystemHandler = (option) => {
    console.log(option);
    setAnchorEl(null);
    if (optionRef.current !== option) {
      history.push(`${pathName}?typeId=${option}`);
      optionRef.current = option;
      props.close();
      props.clearVars();
    }
  };

  const searchInputHandler = (input) => {
    props.findTypeName(input);
  };

  return (
    <div className={classes.search_subsystem_layout}>
      {state.types
        .filter((item) => item.id === typeIdRef.current && item.url)
        .map((item) => (
          <a
            href={item.url}
            target={"_blank"}
            rel="noreferrer"
            style={{ position: "absolute", top: 15, textDecoration: "none" }}
          >
            <i className={"sl sl-icon-login"} />
            {t(" Σύνδεση")}
          </a>
        ))}
      <img
        src={search_icon}
        alt="search icon"
        className={classes.search_style}
      />
      <InputOnChange
        inputPlaceHolder={t("Αναζήτηση")}
        className={classes.search_input}
        type="search"
        setSearch={searchInputHandler}
      />
      <div className={classes.select_place}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          style={{ textTransform: "none" }}
          onClick={handleClickListItem}
        >
          {t("Υποσύστημα")}&nbsp;
          <span
            className={
              anchorEl
                ? [classes.rotate, classes.animation].join(" ")
                : classes.rotate
            }
          >
            &#8250;
          </span>
        </Button>

        <Popover
          id={"simple-popover"}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SubsystemPopupMenu
            subsystems={state.types}
            setOption={clickSubSystemHandler}
          />
        </Popover>

        {/* -------------------------------------------- */}

        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClickListItem2}
        >
          <Badge
            badgeContent={props.filtersVars.length}
            max={999}
            color="secondary"
            style={{ textTransform: "none" }}
          >
            {t("Μεταβλητή")}&nbsp;
            <span
              className={
                anchorEl2
                  ? [classes.rotate, classes.animation].join(" ")
                  : classes.rotate
              }
            >
              &#8250;
            </span>
          </Badge>
        </Button>
        <Popover
          id={"simple-popover"}
          open={Boolean(anchorEl2)}
          anchorEl={anchorEl2}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <SubsystemPopupMenuVars
            varsList={varsList}
            setVars={props.setVars}
            filtersVars={props.filtersVars}
            close={handleClose}
            clearVars={props.clearVars}
            type={optionRef.current}
          />
        </Popover>
      </div>
    </div>
  );
};

export default SubsystemSearch;
