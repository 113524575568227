import classes from "./Header.module.css";
import city from "./../../../SMARTCITY/assets/images/city.png";
import cityEn from "./../../../SMARTCITY/assets/images/cityEn.png";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import LanguageContext from "./../../hooks/language-context";
import cookies from "js-cookie";
import i18next from "i18next";

const Header = () => {
  const history = useHistory();
  const languageCtx = useContext(LanguageContext);
  const currentLanguageCode = cookies.get("i18next") || "el";

  console.log(languageCtx);
  const redirectHandler = () =>
    history.location.pathname !== "/" && history.push("/");

  return (
    <div className={[classes.header, classes.flexContainer].join(" ")}>
      <div className={classes.app_logo}>
        <img
          src={languageCtx.languageCode === "en" ? city : city}
          alt="smartIsCity logo"
          width="100%"
          onClick={redirectHandler}
        />
      </div>
      <div className={classes.sponsor_logo__container}>
        <div className={classes.changeLanguage__Wrapper}>
          <span
            className={
              languageCtx.languageCode === "el" ? classes.activeLanguage : null
            }
            onClick={() => {
              if (currentLanguageCode === "el") return;
              i18next
                .changeLanguage("el")
                .catch((error) => console.warn(error));
              languageCtx.languageCodeHandler("el");
            }}
          >
            EL
          </span>{" "}
          |{" "}
          <span
            className={
              languageCtx.languageCode === "en" ? classes.activeLanguage : null
            }
            onClick={() => {
              if (currentLanguageCode === "en") return;
              i18next
                .changeLanguage("en")
                .catch((error) => console.warn(error));
              languageCtx.languageCodeHandler("en");
            }}
          >
            EN
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
