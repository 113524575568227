import classes from "./WelcomeMessage.module.css";
import apple from "./../../../assets/images/apple.png";
import android from "./../../../assets/images/android.png";
import information from "./../../../assets/images/information_project.png";
import { useTranslation } from "react-i18next";
import {
  androidAppLink,
  informationLink,
  informationLinkEN,
  iosAppLink,
  welcomeMessageContent,
} from "../../../../SMARTCITY/hardCodeData/DATA";
import { useContext } from "react";
import LanguageContext from "../../../hooks/language-context";
import { useHistory } from "react-router-dom";
import SvgTermsofuse2 from "../../../assets/icons/Termsofuse2";
import sponsor from "./../../../../SMARTCITY/assets/images/sponsor.png";

const WelcomeMessage = () => {
  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);
  const history = useHistory();
  return (
    <div className={classes.welcome_placeholder}>
      <p>{t(welcomeMessageContent[0])}</p>
      <p>{t(welcomeMessageContent[1])}</p>

      <div>
      <div className={classes.logoimgtxt}><a href="https://www.pogoni.gr/index.php/smart-city" target="_blank"><img src={sponsor} alt="sponsor logo" className={classes.logo_normal}/></a></div>
      <span className={classes.logotxt}>Με τη συγχρηματοδότηση της Ελλάδας και της Ευρωπαϊκής Ένωσης</span>
      <div
          style={{ marginBottom: 5 }}
          onClick={() => history.push("/terms-of-use")}
        >
          <SvgTermsofuse2 width={35} />
          {languageCtx.languageCode === "el" ? (
       <p>{t("Δήλωση Προσβασιμότητας")}</p>
      ) : (
        <p>{t("Accessibility Statement")}</p>
      )}
        </div>
        <div
          onClick={() =>
            window.open(
              languageCtx.languageCode === "en"
                ? informationLinkEN
                : informationLink
            )
          }
        >
          <img src={information} className={classes.inf_normal} alt={"information-application"} width={35} />
          <p>{t("Πληροφορίες για το έργο")}</p>
        </div>
        <div>
          {/* <img
            src={apple}
            alt={"apple-application"}
            width={150}
            onClick={() => window.open(iosAppLink)}
          />
          <img
            src={android}
            alt={"android-application"}
            width={150}
            onClick={() => window.open(androidAppLink)}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
