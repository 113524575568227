import classes from "./FastChart.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useRef, useState } from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { useStore } from "../../../hooks/store";
import ChartLine from "../ChartLine";
import MemorandumValues from "../MemorandumValues/MemorandumValues";
import {
  allBinsCharts,
  specificBinChart,
} from "../../../../SMARTCITY/hardCodeData/DATA";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    "& .Mui-disabled": {
      color: "rgba(255, 255, 255, 0.4)",
    },
    marginTop: theme.spacing(2),
    width: "100%",
    color: "black",
    textAlign: "left",
    paddingLeft: "5px",
    "&:before": {
      borderBottom: "2px solid rgb(92, 99, 106)",
    },
    "&:hover:before": {
      borderBottom: "4px solid rgb(92, 99, 106) !important",
    },
    "&:after": {
      borderBottom: "4px solid rgb(92, 99, 106)",
    },
    "& .MuiSelect-icon": {
      color: "black",
    },
  },
}));

const initStatisticData = {
  description: "Πληρότητα (μέσος όρος τελευταίων 30 ημερών)",
  name: "Κάδοι",
  unit: "%",
  details: [],
};

const FastChart = ({ typeId }) => {
  const state = useStore()[0];
  const classes_select = useStyles();
  const categoryName = useRef({ single: "", plural: "" });

  const [activeOption, setActiveOption] = useState(0);
  const [selectList, setSelectList] = useState([]);

  const [binsList, setBinsList] = useState([]);
  const [percBins, setPercBins] = useState([]); // 90%, 75%, 55%, 15%, 0%

  const [statisticData, setStatisticData] = useState(initStatisticData);

  const controller = new AbortController();
  const { signal } = controller;

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (typeId === "1")
      categoryName.current = {
        single: "Περιβαλλοντικός",
        plural: "Περιβαλλοντικοί",
      };
    else if (typeId === "9")
      categoryName.current = { single: "Κάδος", plural: "Κάδοι" };
  }, [typeId]);

  useEffect(() => {
    if (typeId === "9") {
      setActiveOption(0);
      setSelectList(binsList);
    } else setSelectList([]);
  }, [binsList, typeId]);

  useEffect(() => {
    setBinsList(state.sensors.filter((item) => item.typeId === "9"));
  }, [state]);

  useEffect(() => {
    if (activeOption === 0)
      getStatisticsAllBins().catch((error) => console.error(error));
    else getStatisticsFromABin().catch((error) => console.error(error));
  }, [activeOption]);

  useEffect(() => {
    if (binsList.length > 0) {
      let avg = 0;
      let min = 100;
      let max = 0;
      const fullBins = [];
      const bins = [];
      const halfBins = [];
      const lowBins = [];
      const emptyBins = [];
      binsList.map((bin) => {
        bin.metrics.map((item) => {
          if (item.content === "Ποσοστό πληρότητας κάδου") {
            const value = parseFloat(item.value.replace("%", ""));

            if (value > 90) fullBins.push(bin);
            else if (value > 75) bins.push(bin);
            else if (value > 55) halfBins.push(bin);
            else if (value > 15) lowBins.push(bin);
            else emptyBins.push(bin);

            avg += value;
            if (value > max) max = value;
            if (value < min) min = value;
          }
        });
      });

      const garbages = [];
      garbages.push(fullBins, bins, halfBins, lowBins, emptyBins);
      setPercBins(garbages);
    }
  }, [binsList]);

  const handleChange = (event) => {
    setActiveOption(event.target.value);
  };

  const getStatisticsAllBins = async () => {
    const response = await fetch(allBinsCharts, { signal });

    if (!response || !response.ok) return;

    const data = await response.json();

    let avg = 0;
    let min = 100;
    let max = 0;

    for (let i = 0; i < data.length; i++) {
      if (Number(data[i].value) > max) max = Number(data[i].value);
      if (Number(data[i].value) < min) min = Number(data[i].value);
      avg += Number(data[i].value);
    }

    setStatisticData({
      ...initStatisticData,
      min,
      max,
      avg: avg / data.length,
      details: data.map((item) => ({
        date_insert: `id/${item.id}`,
        datetime: `id/${item.id}`,
        val: item.value,
      })),
    });
  };

  const getStatisticsFromABin = async () => {
    const response = await fetch(`${specificBinChart}&id=${activeOption}`, {
      signal,
    });

    if (!response || !response.ok) return;

    const data = await response.json();

    let avg = 0;
    let min = 100;
    let max = 0;

    for (let i = 0; i < data.length; i++) {
      if (Number(data[i].value) > max) max = Number(data[i].value);
      if (Number(data[i].value) < min) min = Number(data[i].value);
      avg += Number(data[i].value);
    }

    setStatisticData({
      ...initStatisticData,
      min,
      max,
      avg: avg / data.length,
      details: data.map((item) => ({
        date_insert: item.labelDate,
        datetime: item.labelDate.split("-").reverse().join("/"),
        val: item.value,
      })),
    });
  };

  return (
    <div className={classes.fastChart}>
      <div style={{ maxWidth: "50%", margin: "20px auto 20px" }}>
        <InputLabel
          style={{
            margin: "0 auto",
            textAlign: "left",
            color: "black",
          }}
        >
          {categoryName.current.single}
        </InputLabel>

        <Select
          className={classes_select.selectEmpty}
          value={activeOption}
          onChange={handleChange}
        >
          <MenuItem
            value={0}
          >{`Όλοι οι ${categoryName.current.plural}`}</MenuItem>
          {selectList.map((item, index) => (
            <MenuItem key={index} value={item.id}>
               ({item.id}) {item.title}
            </MenuItem>
          ))}
        </Select>
      </div>

      {typeId === "9" && (
        <div style={{ padding: "0 10px" }}>
          {statisticData && <ChartLine data={statisticData} type={3} />}
        </div>
      )}

      {typeId === "9" && (
        <>
          <MemorandumValues
            values={percBins}
            title={categoryName.current.plural}
            labels={["90%-100%", "75%-89%", "55%-74%", "15%-54%", "0%-14%"]}
          />
        </>
      )}
    </div>
  );
};

export default FastChart;
