import classes from "./TermsOfUse.module.css";
import { Link } from "react-router-dom";
import LanguageContext from "../../../TEMPLATE/hooks/language-context";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

const TermsOfUseEn = () => {

  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div className={`${classes.privacy}`}>
      <div>
     <p> <span ><h4>PART 1</h4></span></p>

     <p><span ><h4>ACCESSIBILITY STATEMENT</h4></span></p>

     <p><span>The </span><b>MUNICIPALITY OF POGONI </b><span>is committed to making the </span><i><span>website: pogoni.smartiscity.gr </span></i><span >accessible, in accordance with the provisions of Law 4727/2020 (Government Gazette 184 A' 23-09-2020), Chapter H' "DIGITAL ACCESSIBILITY (INTEGRATION INTO GREEK LEGISLATION OF DIRECTIVE (EU) 2016/2102 OF OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL, OF 26 OCTOBER 2016, ON THE ACCESSIBILITY OF THE WEBSITES AND APPLICATIONS FOR PORTABLE DEVICES OF PUBLIC SECTOR ORGANIZATIONS)" which incorporates into the national legal order Directive (EU) 2016/20</span>1
      </p>

<p><span >This accessibility statement applies to: the website </span><i><span >pogoni.smartiscity.gr </span></i></p>

<p><b><h4>Compliance status</h4></b></p>

<p><span >The current website </span><i><span >pogoni.smartiscity.gr </span></i><span > is fully compliant with the Web Content Accessibility Guidelines (WCAG 2.1 ΑΑ).</span></p>

<p><b><h4>Preparation of this accessibility statement</h4></b></p>

<p><span >This statement was drawn up on 11-21-2022.</span></p>

<p><span >The actual assessment of the website's compliance with the requirements of Directive (EU) 2016/2102 was carried out with a self-assessment carried out by the public sector organization and with the use of</span><a href="https://userway.org/"><span >UserWay Website Accessibility Widget</span></a><span > </span>
</p>
<p><span >Statement last revised on 21-11-2022.</span></p>

<p><b><h4>Submit comments and contact information</h4></b></p>

<p><span>According to the provisions of Law 4727/2020 (Government Gazette 184 A' 23-09-2020), article 45, every interested party has the right to submit observations or request for information regarding the compliance status or request for missing information.</span>
</p>
<p><span >To the </span><b>MUNICIPALITY OF POGONIOU </b><span >the above is submitted with a relevant Application of the interested party which is submitted through the contact form on the website</span><a href="https ://www.pogoni.gr/index.php/communication" target='_blank'><span >https://www.pogoni.gr/index.php/communication.
</span></a>
</p>
<p><span ><h4>Service Responsible for accessibility issues and for processing requests submitted through the feedback mechanism</h4></span></p>
<h4>Planning, Development Directorate</h4>

<p><span >e-mail: </span>dimospogoniou@pogoni.gr<span > </span></p>

<p><b><h4>Execution process</h4></b></p>

<p><span >The competent office of the </span><b>POGONI MUNICIPALITY</b><span > responds to anyone interested in an appropriate way.</span></p>
<p><span>In case of an unsatisfactory response to the information or to the requests, the interested party has the right to appeal in accordance with the provision to the competent institutional authorities, such as by submitting a relevant Report to the Ombudsman (https://www.synigoros.gr ).</span>
</p>
<p><span ><h4>PART 2</h4></span></p>

<p><b><h4>Enabling the Accessibility Menu</h4></b></p>

<p><span>The website's accessibility menu</span><i><span >pogoni.smartiscity.gr </span></i><span > can be activated by clicking the accessibility menu icon displayed at the top of the page. After enabling the accessibility menu, please wait a while for the accessibility menu to load in its entirety.</span>
</p>
<p><i><span >pogoni.smartiscity.gr</span></i><span > continues its efforts to constantly improve the accessibility of the website with the belief that it is our collective moral obligation to enable seamless, accessible and seamless use for those of us with disabilities as well.</span>
</p>
<p><span>In an ongoing effort to continuously improve and fix accessibility issues, we regularly scan pogoni.smartiscity.gr with UserWay's accessibility scanner to identify and fix any potential accessibility barriers to the site. Despite our efforts to make all pages and content on pogoni.smartiscity.gr fully accessible, some content may not yet be fully adapted to the strictest accessibility standards.</span>
</p>
        <p style={{ float: "right", fontWeight: 600, marginTop: 20 }}>
          <Link to={"/"}>Back</Link>
        </p>
      </div>
    </div>
  );
};

export default TermsOfUseEn;