import TermsOfUseEl from "../components/TermsOfUse/TermsOfUse";
import TermsOfUseEn from "../components/TermsOfUse/TermsOfUseEn";
import LanguageContext from "../hooks/language-context";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

const TermsOfUse = () => {
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div>
       
      {languageCtx.languageCode === "el" ? (
       <TermsOfUseEl /> 
      ) : (
        <TermsOfUseEn /> 
      )}
    </div>
  );
};

export default TermsOfUse;
