export const informationLink = "https://www.smartiscity.gr/dimos-pogoniou/";
export const informationLinkEN =
  "https://www.smartiscity.gr/en/dimos-pogoniou/";

export const welcomeMessageContent = [
  "Ο Δήμος Πωγωνίου σας καλωσορίζει στην Πλατφόρμα Εξυπνης Πόλης.",
  "Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.",
];
export const androidAppLink =
  "";
export const iosAppLink =
  "";
export const baseUrl = "https://pogoni.smartiscity.gr";

export const allTypesUrl = baseUrl + "/api/api.php?func=sensortypes";
export const allSensorsUrl = baseUrl + "/api/api.php?func=allsensors";

export const UvUrl = baseUrl + "/api/api.php?func=indexes";
export const enviURL = baseUrl + "/api/api.php?func=envi";
export const meshURL = baseUrl + "/api/api.php?func=mesh";
export const meteoURL = baseUrl + "/api/api.php?func=meteo";
export const poiURL = baseUrl + "/api/api.php?func=pois";
export const fleetoUrl = baseUrl + "/api/api.php?func=fleeto";
export const binsUrl = baseUrl + "/api/api.php?func=bins";
export const hydroUrl = baseUrl + "/api/api.php?func=hydro";
export const weatherUrl = baseUrl + "/api/api.php?func=forecast";
export const wifiUrl = baseUrl + "/api/api.php?func=hotspot";
export const hamURL = baseUrl + "/api/api.php?func=ham";

export const allBinsCharts = baseUrl + "/api/api.php?func=allbinstats";
export const specificBinChart = baseUrl + "/api/api.php?func=binstats";

export const chartsUrls = baseUrl + "/api/api.php?func=stats";

export const gallerySmall = baseUrl + "/gallery/vars/Small/";
export const galleryBig = baseUrl + "/gallery/vars/Big/";

export const hotSpotParameterId = "103";

export const cityCenter = {
  lat: 39.8889629671685, 
  lng: 20.624464750072534,
};

// settings
export const showEnvironmentAddress = true;
