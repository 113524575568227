import { Fragment } from "react";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
// import Entity from "../../UI/box/entity/Entity";
import LanguageContext from "../../../TEMPLATE/hooks/language-context";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

const GIS = () => {
  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();
  // const controller = new AbortController();
  // const { signal } = controller;

  // const [diavData, setDiavData] = useState([]);

  // useEffect(() => {
  //   const callTest = async () => {
  //     const req = await fetch(
  //       "https://zitsa.smartiscity.gr/api/diavouleuseis2.php",
  //       { signal }
  //     ).catch((error) => console.log(error));
  //
  //     if (req === undefined || !req.ok) return [];
  //     const response = await req.json();
  //
  //     setDiavData(response);
  //   };
  //
  //   callTest();
  //
  //   return () => controller.abort();
  // }, []);
  return (
    <Fragment>
      <BoxTitle
        icon={"im im-icon-Map2"}
        title={t("ΕΦΑΡΜΟΓΗ ΧΑΡΤΟΓΡΑΦΗΣΗΣ")}
        externalLink={"https://gis.pogoni.gr"}
        infoContext={
          t("Δείτε τα χαρτογραφικά δεδομένα της πολεοδομικής και περιβαλλοντιικής φύσης και ενημερωθείτε για το δίκτυο υποδομών του Δήμου μας")
        }
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      {/*{diavData.map((data, index) => (*/}
      {/*  <Entity key={index} content={data.name} value={data.value} />*/}
      {/*))}*/}
      <p
        style={{
          marginTop: 10,
          color: "black",
          padding: 10,
          textAlign: "center",
          lineHeight: "1.5",
        }}
      >
        {t("Υποδομή Γεωχωρικών Πληροφοριών (Υ.Γε.Π.)")}
      </p>
      <button
        type={"button"}
        style={{
          width: 180,
          height: 60,
          marginLeft: "calc((100% - 180px ) /2)",
          marginTop: 20,
          borderRadius: "30px",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => window.open("https://gis.pogoni.gr", "_blank")}
      >
        <span style={{ fontWeight: "700", fontSize: "16px" }}>{t("Είσοδος")}</span>
      </button>
    </Fragment>
  );
};
export default GIS;
