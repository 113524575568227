import classes from "./MemorandumValues.module.css";
import { useEffect, useRef } from "react";

const MemorandumValues = (props) => {
  const { title, labels, values, className } = props;
  const counter = useRef(0);

  useEffect(() => {
    let tempCounter = 0;
    values.map((item) => (tempCounter += item.length));
    counter.current = tempCounter;
  }, [values]);

  return (
    <div className={[classes.metrics_pm, className].join(" ")}>
      {values.length > 0 && (
        <div>
          <h1>{title}</h1>
          <p>#{counter.current}</p>
        </div>
      )}
      {labels.map((item, index) => (
        <div key={index}>
          <h1>{item}</h1>
          <p>#{values.length > 0 ? values[index].length : "0"}</p>
        </div>
      ))}
    </div>
  );
};

export default MemorandumValues;
