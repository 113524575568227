import { Fragment, useEffect, useState, useContext } from "react";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
import BoxSubTitle from "../../../TEMPLATE/components/box/subTitle/BoxSubTitle";
import BoxEntity from "../../../TEMPLATE/components/box/entity/BoxEntity";
import CustomSlider from "../../../TEMPLATE/components/customSlider/CustomSlider";
import classes from "./IanosEnergy.module.css";
import LanguageContext from "../../../TEMPLATE/hooks/language-context";
import { useTranslation } from "react-i18next";

const IanosEnergyUrl =
  "https://panel.ianosenergy.gr/api/api.php?func=smart&region=16";

const IanosEnergy = () => {
  const [data, setData] = useState([]);

  const controller = new AbortController();
  const { signal } = controller;

  const languageCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    const getIanosEnergyData = async () => {
      const req = await fetch(IanosEnergyUrl, { signal }).catch((error) =>
        console.warn(error)
      );
      if (req === undefined || !req.ok) return [];
      const response = await req.json();

      const structureData = response.map((yearlyMetrics) => {
        return yearlyMetrics.map((metric) => {
          if (metric.name === "Έτος") return metric;

          const putDots = metric.value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return { ...metric, value: putDots };
        });
      });

      setData(structureData.reverse());
    };
    getIanosEnergyData();

    return () => {
      controller.abort();
      setData([]);
    };
  }, []);

  return (
    <div>
      <BoxTitle
        icon={"im im-icon-Electricity"}
        title={t("ΕΝΕΡΓΕΙΑΚΗ ΚΑΤΑΝΑΛΩΣΗ ΔΗΜΟΥ")}
        infoContext={"Δείτε την ενεργειακή κατανάλωση του Δήμου μας τα τελευταία έτη"}
        externalLink={"https://panel.ianosenergy.gr/index.php?dashboard=1&region=16"}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />

      <div>
        <CustomSlider>
          {data.map((block, index) => (
            <Fragment key={index}>
              {block.map((item) => (
                <BoxEntity
                  key={item.name}
                  icon={null}
                  content={item.name}
                  value={item.value}
                  className={classes.removeIconFromEntity}
                />
              ))}
            </Fragment>
          ))}
        </CustomSlider>
      </div>
    </div>
  );
};

export default IanosEnergy;
